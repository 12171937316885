import {readUser, updateUser, readAccountType, readCompanies, createUser, profileUpdate} from '../../services/UserService'

export const user = {
    name: 'user',
    namespaced: true,
    state: () => ({
      users: [
        { id: 1, name: "Admin", created_at: "12321312" },
        { id: 2, name: "User", created_at: "12321312" },
        { id: 3, name: "Customer Care", created_at: "1232131" },
      ],
      companies : [],
      accounttypes: [],
    }),
    mutations: {

      setAccountTypes(state, accounttypes){
        state.accounttypes = accounttypes;
      },

      setUser(state, users){
        state.users = users;
      },
      setCompanies(state, companies){
        state.companies = companies;
      },
      updateUser(state, users){
      // TODO 
      },
      createUser(state, users){
        // TODO 
        },

    },
    actions: {

      async readAccountTypes({commit}, payload){
        const data = await readAccountType();
        commit('setAccountTypes', data.result)
      },

      async readCompanies({commit}, payload){
        const data = await readCompanies();
        commit('setCompanies', data.result)
      },

      async readUsers({commit}, payload){
        const data = await readUser(payload);
        commit('setUser', data.result)
      },
      async createUsers({commit}, payload){
        await createUser(payload);
      },
      async profileUpdate({commit}, payload){
        await profileUpdate(payload);
      },
      async updateUsers({commit}, payload){
        await updateUser(payload);
      },

    },
    getters: {
      users(state) {
        return state.users;
      },
      accounttypes(state){
        return state.accounttypes;
      },
      companies(state){
        return state.companies;
      }
    },
  };
  