import {edit, formCreate, manageHosting} from "../../services/HostingService";

export const hosting = {
    namespaced: true,
    state: {
        formCreate: {
            customers: [],
            base_packages: [],
            availability: [],
            providers: [],
            types: []
        },
        form: {
            id: null,
            name: '',
            production_price_month: null,
            staging_price_month: null,
            yearly_price_increase: null,
            description: '',
            availability: '',
            hosting_type_id: null,
            moodle_url: null,
            hosting_provider_id: null,
            base_package_id: null,
            backup_price_monthly: null,
            customers: null
        }
    },
    mutations: {
        CHANGE_FORM_CREATE(state, data): void {
            state.formCreate = data;
        },
        CHANGE_NAME(state, value) {
            state.form.name = value
        },
        CHANGE_PRODUCTION_PRICE(state, value) {
            state.form.production_price_month = value
        },
        CHANGE_STAGING_PRICE(state, value) {
            state.form.staging_price_month = value
        },
        CHANGE_YEARLY_PRICE_INCREASE(state, value) {
            state.form.yearly_price_increase = value
        },
        CHANGE_DESCRIPTION(state, value) {
            state.form.description = value
        },
        CHANGE_AVAILABILITY(state, value) {
            state.form.availability = value
        },
        CHANGE_HOSTING_TYPE_ID(state, value) {
            state.form.hosting_type_id = value
        },
        CHANGE_HOSTING_PROVIDER_ID(state, value) {
            state.form.hosting_provider_id = value
        },
        CHANGE_BASE_PACKAGE_ID(state, value) {
            state.form.base_package_id = value
        },
        CHANGE_BACKUP_PRICE_MONTHLY(state, value) {
            state.form.backup_price_monthly = value
        },
        CHANGE_CUSTOMERS(state, value) {
            state.form.customers = value
        },
        CHANGE_MOODLE_URL(state, value) {
            state.form.moodle_url = value
        },
        CHANGE_FORM(state, value) {
            Object.assign(state.form, value);
        },
        RESET_STATE(state, value) {
            Object.assign(state.form, {
                id: null,
                name: '',
                production_price_month: null,
                staging_price_month: null,
                yearly_price_increase: null,
                description: '',
                availability: '',
                hosting_type_id: null,
                moodle_url: null,
                hosting_provider_id: null,
                base_package_id: null,
                backup_price_monthly: null,
                customers: null
            });
        }
    },
    actions: {
        async fetchFormCreate({ commit }): Promise<any> {
            const response = await formCreate();
            commit('CHANGE_FORM_CREATE', response.result);
        },
        updateName({ commit }, value) {
            commit('CHANGE_NAME', value)
        },
        updateProductionPrice({ commit }, value) {
            commit('CHANGE_PRODUCTION_PRICE', value)
        },
        updateStagingPrice({ commit }, value) {
            commit('CHANGE_STAGING_PRICE', value)
        },
        updateYearlyPriceIncrease({ commit }, value) {
            commit('CHANGE_YEARLY_PRICE_INCREASE', value)
        },
        updateDescription({ commit }, value) {
            commit('CHANGE_DESCRIPTION', value)
        },
        updateAvailability({ commit }, value) {
            commit('CHANGE_AVAILABILITY', value)
        },
        updateHostingTypeId({ commit }, value) {
            commit('CHANGE_HOSTING_TYPE_ID', value)
        },
        updateHostingProviderId({ commit }, value) {
            commit('CHANGE_HOSTING_PROVIDER_ID', value)
        },
        updateBasePackageId({ commit }, value) {
            commit('CHANGE_BASE_PACKAGE_ID', value)
        },
        updateBackupPriceMonthly({ commit }, value) {
            commit('CHANGE_BACKUP_PRICE_MONTHLY', value)
        },
        updateCustomers({ commit }, value) {
            commit('CHANGE_CUSTOMERS', value)
        },
        updateMoodleUrl({ commit }, value) {
            commit('CHANGE_MOODLE_URL', value)
        },
        async manageHosting({ commit, state }): Promise<any> {
            return await manageHosting(state.form);
        },
        async edit({ commit, state }, params): Promise<any> {
            const response = await edit(params.id);
            commit('CHANGE_FORM', response);
        },
        reset({ commit }){
            commit('RESET_STATE');
        }
    }
}