import {AxiosResponse} from "axios";
import {GET, POST, DELETE, HandleError, HandleSuccess} from "@/services/API";
import {Response} from "@/services/Response";

export async function listProducts(page:Number, perPage:Number, filter:String, sortBy: String, sortDesc: String): Promise<Response> {
    try {
        return await GET('product/list', {
            params: {
                filter: filter,
                page: page,
                per_page: perPage,
                sort_by: sortBy,
                sort_desc: sortDesc
            }
        });
    } catch (error) {
        HandleError({msg : "Error loading product list. Please try again."});
        return {
            status_code: 500,
            status: "error",
            message: "An error occurred",
            result: {
                result : {
                    data: [],
                    total: 0
                }
            }
          };
    }
    
}

export async function deleteProductModule(product:Number, module:String) {
    try {
        await DELETE(`product/${product}?module=${module}`, [])
        .then(response => {
            HandleSuccess(response);
        });
    } catch (error) {
        HandleError(error);
    }
    
}

export async function storeProduct(data): Promise<Response> {
    return await POST('product/store', data);
}

export async function formCreate(): Promise<Response> {
    return await GET('product/form_create');
}


export async function edit(id): Promise<Response> {
    return await GET(`product/edit/${id}`);
}

export async function isProductNameAvailable(name): Promise<Response> {
    return await GET(`product/availability?name=${name}`);
}