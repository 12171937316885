import { GET, POST, HandleError, HandleSuccess } from "@/services/API";
import { Response } from "@/services/Response";

export async function formCreate(paramId, env): Promise<Response> {
    return await GET(`product/setting/form_create/${env}/${paramId}`);
}

export async function formCreatePlugins(params): Promise<Response> {
    return await GET(`product/setting/form-data/plugins`, {
        params : params
    });
}

export async function formData(paramId, env): Promise<Response> {
    return await GET(`product/setting/form_data/${env}/${paramId}`);
}

export async function updateProductSettings(id, data): Promise<Response> {
    return await POST(`product/setting/update/${id}`, data);
}

export async function updateProductPlugins(id, data): Promise<Response> {
    return await POST(`product/setting/update/${id}/plugins`, data);
}

export async function triggerBuildPipeline(id, data){
    await POST(`product/${id}/build`, data)
    .then(response => {
        HandleSuccess(response);
    });
}

export async function syncPluginsFromGit(productId, data){
    await POST(`product/${productId}/sync-plugins`, data)
    .then(response => {
        HandleSuccess(response);
    });
}