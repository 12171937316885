import {Response} from "@/services/Response";
import {GET, POST} from "@/services/API";

export async function listHosting(page:Number, perPage:Number, filter:String, sortBy: String, sortDesc: String): Promise<Response> {
    return await GET('hosting/list', {
        params: {
            filter: filter,
            page: page,
            per_page: perPage,
            sort_by: sortBy,
            sort_desc: sortDesc
        }
    });
}

export async function formCreate(): Promise<Response> {
    return await GET('hosting/form_create');
}

export async function manageHosting(data): Promise<Response> {

    if(data.id) {
        return await POST(`hosting/update/${data.id}`, data);
    }

    return await POST('hosting/store', data);
}

export async function edit(id): Promise<Response> {
    return await GET(`hosting/edit/${id}`);
}