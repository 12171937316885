import {updateRolePermission, readRole, readModule, createModule, updateModule , readPermission, createPermission, updatePermission} from '../../services/AccessControlService'

export const accesscontrol = {
  name: 'accesscontrol',
  namespaced: true,
  state: () => ({
    rolePermissions:[],
    modules: [
      { id: 999, name: "TEST MODULE", created_at: "0" },
    ],
    permissions: [
        { id: 1, module: "999", name : "Test Permission", codename : "TEST_PERMISSION", action : "create", description : "Desc", created_at: "0" },
    ],
    roles: [
      { id: 1, name: "TEST ROLE", created_at: "0" },
    ],
  }),
  mutations: {
    updateRolePermissions(state, rolePermissions){
      state.rolePermissions = rolePermissions;
    },
    setRole(state, roles){
      state.roles = roles;
    },
    setPermission(state, permissions){
      state.permissions = permissions;
    },
    setModules (state, modules){
      state.modules = modules;
    },
    addModule (state, modules){
      // TODO write add module code
    },
    updateModules(state, module){
      // TODO write update logic 
    },
    deleteModules(state, module){
      // TODO write delete logic
    }
  },
  actions: {
    async updateRolePermissions({commit}, payload){
      const result = await updateRolePermission(payload);
      commit('updateRolePermissions', payload)
    },

    async readRoles({commit}, module){
      const data = await readRole();
      commit('setRole', data.result)
    },

    async readModules({commit}, module){
      const data = await readModule();
      commit('setModules', data.result)
    },
    async createModules({commit}, data){
      const result = await createModule(data);
      commit('addModule', data)
    },
    async updateModules({commit}, payload){
      const result = await updateModule(payload);
      commit('updateModules', payload)
    },


    async readPermissions({commit}, module){
      const data = await readPermission();
      commit('setPermission', data.result)
    },
    async createPermissions({commit}, data){
      const result = await createPermission(data);
      commit('addPermission', data)
    },
    async updatePermissions({commit}, payload){
      const result = await updatePermission(payload);
      commit('updatePermission', payload)
    }
  },
  getters: {
    roles(state) {
      return state.roles;
    },
    modules(state) {
      return state.modules;
    },
    permissions(state) {
        return state.permissions;
      },
  },
};
