import {edit, formCreate, manageSoftware, getSoftwareVersions} from "../../services/SoftwareService";

export const software = {
    namespaced: true,
    state: {
        form: {
            id: null,
            name: null,
            git_url: null,
            git_version_type_id: null,
            version_supported: null
        },
        formCreate: {
            types: [],
            base_git_url: null
        },
        software_versions: []
    },
    mutations: {
        CHANGE_FORM_CREATE(state, data): void {
            state.formCreate = data;
        },
        CHANGE_SOFTWARE_VERSIONS(state, data): void {
            state.software_versions = data;
        },
        CHANGE_SELECTED_SOFTWARE_VERSIONS(state, data): void {
            state.form.version_supported = data;
        },
        CHANGE_NAME(state, value) {
            state.form.name = value;
        },
        CHANGE_GIT_URL(state, value) {
            state.form.git_url = value;
        },
        CHANGE_GIT_VERSION_TYPE_ID(state, value) {
            state.form.git_version_type_id = value;
        },
        CHANGE_FORM(state, value) {
            Object.assign(state.form, value);
        },
        RESET_STATE(state, value) {
            Object.assign(state.form, {
                id: null,
                name: null,
                git_url: null,
                git_version_type_id: null
            });
        }
    },
    actions: {
        async fetchFormCreate({ commit }): Promise<any> {
            const response = await formCreate();
            commit('CHANGE_FORM_CREATE', response.result);
        },
        updateName({ commit }, value) {
            commit('CHANGE_NAME', value);
        },
        updateGitUrl({ commit }, value) {
            commit('CHANGE_GIT_URL', value);
        },
        updateGitVersionTypeId({ commit }, value) {
            commit('CHANGE_GIT_VERSION_TYPE_ID', value);
        },
        updateSoftwareVersion({ commit }, value) {
            commit('CHANGE_SELECTED_SOFTWARE_VERSIONS', value);
        },
        async manageSoftware({ commit, state }): Promise<any> {
            commit('CHANGE_GIT_URL', state.formCreate.base_git_url +  state.form.git_url.replace(state.formCreate.base_git_url, ""));
            return await manageSoftware(state.form);
        },
        async getSoftwareVersions({ commit, state }): Promise<any> {
            const response = await getSoftwareVersions(state.form.git_url, state.form.git_version_type_id);
            commit('CHANGE_SOFTWARE_VERSIONS', response.result);
        },
        async edit({ commit, state }, params): Promise<any> {
            const formResponse = await edit(params.id);
            commit('CHANGE_FORM', formResponse);
            const versionsResponse = await getSoftwareVersions(state.form.git_url, state.form.git_version_type_id);
            commit('CHANGE_SOFTWARE_VERSIONS', versionsResponse.result);
        },
        reset({ commit }){
            commit('RESET_STATE');
        }
    }
}