import {Response} from "@/services/Response";
import {GET, POST} from "@/services/API";

export async function listCustomerProducts(id:Number,filter:String): Promise<Response> {
    return await GET('customer/product/list', {
            params: {
                filter: filter,
                id: id
            }
        }
    );
}

export async function formCreate(): Promise<Response> {
    return await GET('customer/product/form_create');
}

export async function editCustomerProduct(data): Promise<Response> {
    return await POST('customer/product/edit', data);
}

export async function formEdit(productId, customerId): Promise<Response> {
    return await GET('customer/product/form_edit', {
        params: {
            productId: productId,
            customerId: customerId
        }
    });
}
