import {AxiosResponse, CancelToken} from "axios/index";
import {GET, POST, DELETE, PUT} from "@/services/API";
import {Response} from "@/services/Response";

export async function formCreate(): Promise<Response> {
    return await GET('installation/form_create');
}

export async function storeInstallation(data): Promise<Response> {
    return await POST('installation/store', data);
}

export async function editInstallation(data): Promise<Response> {
    return await PUT(`installation/${data.id}`, data);
}

export async function deleteInstallation(id): Promise<Response> {
    return await DELETE('installation/delete',  {
        params: {
            id: id
        }
    });
}

export async function listInstallations(page:Number, perPage:Number, filter:String, sortBy: String, sortDesc: String): Promise<Response> {

    return await GET('installation/list', {
        params: {
            filter: filter,
            page: page,
            per_page: perPage,
            sort_by: sortBy,
            sort_desc: sortDesc
        }
    });
}

export async function listInstallationsByStatus(): Promise<Response> {

    return await GET('installation/list/status/offline');
}

export async function validateDomain(domain: String): Promise<any> {
    return await GET('installation/validate', {
        params: {
            domain: domain
        }
    });
}

export async function getInstallation(id: Number): Promise<any> {
    return await GET(`installation/${id}`);
}

export async function getInstallationForManage(id: Number): Promise<any> {
    return await GET(`installation/manage/${id}`);
}

export async function buildInstallation(data): Promise<Response> {
    return await POST('installation/build', data);
}
