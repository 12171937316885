import {formCreate, editCustomerProduct, formEdit} from "@/services/CustomerProductService";
import {storeInstallation} from "@/services/InstallationService";

export const CustomerProduct = {
    namespaced: true,
    state: {
        formCreate: {
            maintenance_cost: 0
        },
        form: {
            customer_id: null,
            product_label: null,
            product_id: null,
            include_maintenance: false
        }
    },
    mutations: {
        // Mutations for individual form properties
        UPDATE_CUSTOMER_ID(state, customerId) {
            state.form.customer_id = customerId;
        },
        UPDATE_PIPELINE_NAME(state, pipelineName) {
            state.form.pipeline_name = pipelineName;
        },
        UPDATE_PRODUCT_LABEL(state, productLabel) {
            state.form.product_label = productLabel;
        },
        UPDATE_PRODUCT_ID(state, productId) {
            state.form.product_id = productId;
        },
        UPDATE_INCLUDE_MAINTENANCE(state, includeMaintenance) {
            state.form.include_maintenance = includeMaintenance;
        },
        UPDATE_FORM_CREATE(state, formCreate) {
            state.formCreate = formCreate;
        },
        UPDATE_FORM_EDIT(state, formEdit) {
            state.form = formEdit;
        },
        RESET_STATE(state, value) {
            Object.assign(state.form, {
                product_label: null,
                include_maintenance: false
            });
        }
    },
    actions: {
        // Actions to commit mutations
        updateCustomerId({ commit }, customerId) {
            commit('UPDATE_CUSTOMER_ID', customerId);
        },
        updatePipelineName({ commit }, pipelineName) {
            commit('UPDATE_PIPELINE_NAME', pipelineName);
        },
        updatePipelineLabel({ commit }, productLabel) {
            commit('UPDATE_PRODUCT_LABEL', productLabel);
        },
        updateProductId({ commit }, productId) {
            commit('UPDATE_PRODUCT_ID', productId);
        },
        updateIncludeMaintenance({ commit }, includeMaintenance) {
            commit('UPDATE_INCLUDE_MAINTENANCE', includeMaintenance);
        },
        async updateFormCreate({ commit }) {
            const response = await formCreate();
            commit('UPDATE_FORM_CREATE', response.result);
        },
        async updateFormEdit({ commit, state }) {
            const response = await formEdit(state.form.product_id, state.form.customer_id);
            commit('UPDATE_FORM_EDIT', response.result);
        },
        async editCustomerProduct({ commit, state }): Promise<any> {
            return await editCustomerProduct(state.form);
        },
        reset({ commit }){
            commit('RESET_STATE');
        },
    }
}