import Vue from 'vue';
import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import i18n from './i18n';
import store from "./state/store";
import { vMaska } from "maska";
import "@vueform/multiselect/themes/default.css"

import vClickOutside from "click-outside-vue3";
import VueApexCharts from "vue3-apexcharts";
import CKEditor from '@ckeditor/ckeditor5-vue';
import BootstrapVueNext from 'bootstrap-vue-next';

import 'bootstrap-vue-next/dist/bootstrap-vue-next.css';
import './assets/scss/app.scss';
import axios from './plugins/axios';

import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

import Echo from 'laravel-echo';

import Pusher from 'pusher-js';

declare global {
    interface Window {
        Echo:any;
        Pusher: any;
    }
}

window.Pusher = Pusher;

window.Echo = new Echo({
    broadcaster: 'pusher',
    key: process.env.VUE_APP_PUSHER_APP_KEY,  
    wsHost: process.env.VUE_APP_PUSHER_HOST, 
    wsPort: process.env.VUE_APP_PUSHER_PORT,
    cluster: 'us2',
    forceTLS: false,
    disableStats: false,
    enabledTransports: ['ws', 'wss'], // Use 'ws' for non-secure, 'wss' for secure
});


const subscribedEvents = new Set();

export const subscribeToChannel = (channelName: string, eventName: string, callback: Function) => {
    // Create a unique identifier for the channel and event
    const subscriptionKey = `${channelName}:${eventName}`;

    // Check if the channel and event combination is already subscribed
    if (subscribedEvents.has(subscriptionKey)) {
        console.debug("Already subscribed to", subscriptionKey);
        return; // Skip if already subscribed
    }

    // Subscribe to the channel
    window.Echo.channel(channelName)
        .listen(`.${eventName}`, (data) => {
            callback(data); // Call the provided callback with the event data
        });

    // Mark the channel and event as subscribed
    subscribedEvents.add(subscriptionKey);
    
    console.debug("Subscribed to", subscriptionKey);
};

  

export const vueInstance = createApp(App)
    .use(store)
    .use(router)
    .use(VueApexCharts)
    .use(BootstrapVueNext)
    .use(i18n)
    .directive("maska", vMaska)
    .use(CKEditor)
    .use(axios, {
        baseUrl: 'http://' + process.env.VUE_APP_API_BASE_URL || 'http://localhost/api',
        token: localStorage.getItem('jwt')
    })
    .use(vClickOutside)
    .component('VueDatePicker', VueDatePicker)
    .mount('#app');