import { layout } from '../modules/layout';
import {accesscontrol} from '../modules/accesscontrol';
import {installation} from "@/state/modules/installation";
import {installationManage} from "@/state/modules/installation-manage";
import {CustomerProduct} from "@/state/modules/customer-product";
import {hosting} from "@/state/modules/hosting";
import {user} from '../modules/user';
import {software} from "@/state/modules/software";
import {plugin} from "@/state/modules/plugin";
import {customer} from "@/state/modules/customer";
import {product} from "@/state/modules/product";
import {ProductSetting} from "@/state/modules/product-setting";


export const modules =  {
  layout: layout,
  accesscontrol : accesscontrol,
  installation: installation,
  hosting: hosting,
  customerProduct: CustomerProduct,
  productSetting: ProductSetting,
  user : user,
  software: software,
  plugin: plugin,
  customer: customer,
  product: product,
  installationManage: installationManage
}