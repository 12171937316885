<template>
  <router-view></router-view>
</template>

<script>
import emitter from 'tiny-emitter/instance'
import {restart} from "@/services/RemoteAdmin";
export default {
  name: 'App',
  components: {
  },
  created() {
    // window.Echo.channel("alert-processing").listen(".App\\Domain\\Events\\ServerRestartedEvent", (e) => {
    //   emitter.emit('instance-restarted', e.installationId);
    // }); 
  
  }
};
</script>