import {formCreate, storeInstallation, deleteInstallation, validateDomain, getInstallation, editInstallation} from "../../services/InstallationService";

export const installation = {
    namespaced: true,
    state: {
        formCreate: {
            hostingProviders: [],
            hostingPackages: [],
        },
        form: {
            id: null,
            product_id: null,
            customer_id: null,
            hosting_provider: 1,
            domain_type: 'standard',
            domain: null,
            sub_domain: null,
            hosting_package: 1,
            backup_cost: 100,
            include_staging: true,
            include_backup: false,
            general_terms: null,
            billing_terms:null,
            is_domain_taken:true
        },
    },
    getters: {
        getFormCreate(state) {
            return state.formCreate;
        }
    },
    mutations: {
        CHANGE_DOMAIN_TAKEN(state, newValue) {
            state.form.is_domain_taken = newValue;
        },

        CHANGE_FORM_CREATE(state, data): void {
            state.formCreate = data;
        },

        CHANGE_FORM(state, data): void {
            state.form = data;
        },

        CHANGE_HOSTING_PROVIDER(state, newValue) {
            state.form.hosting_provider = newValue;
        },

        CHANGE_DOMAIN_TYPE(state, newValue) {
            state.form.domain_type = newValue;
        },

        CHANGE_DOMAIN(state, newValue) {
            state.form.domain = newValue;
        },

        CHANGE_SUB_DOMAIN(state, newValue) {
            state.form.sub_domain = newValue;
        },

        // Mutate the hosting_package property of the form
        CHANGE_HOSTING_PACKAGE(state, newValue) {
            state.form.hosting_package = newValue;
        },

        CHANGE_BACKUP_COST(state, newValue) {
            state.form.backup_cost = newValue;
        },

        CHANGE_INCLUDE_STAGING(state, newValue) {
            state.form.include_staging = newValue;
        },

        CHANGE_INCLUDE_BACKUP(state, newValue) {
            state.form.include_backup = newValue;
        },

        CHANGE_GENERAL_TERMS(state, newValue) {
            state.form.general_terms = newValue;
        },

        CHANGE_BILLING_TERMS(state, newValue) {
            state.form.billing_terms = newValue;
        },

        CHANGE_PRODUCT_ID(state, newValue) {
            state.form.product_id = newValue;
        },

        CHANGE_CUSTOMER_ID(state, newValue) {
            state.form.customer_id = newValue;
        },

        CHANGE_ID(state, newValue) {
            state.form.id = newValue;
        },
        RESET_STATE(state, value) {
            Object.assign(state.form, {
                id: null,
                product_id: null,
                customer_id: null,
                hosting_provider: 1,
                domain_type: 'standard',
                domain: null,
                sub_domain: null,
                hosting_package: 1,
                backup_cost: 100,
                include_staging: true,
                include_backup: false,
                general_terms: null,
                billing_terms:null,
                is_domain_taken:true
            });
        }
    },
    actions: {
        changeHostingProvider({ commit }, newValue) {
            commit('CHANGE_HOSTING_PROVIDER', newValue);
        },

        changeDomainType({ commit }, newValue) {
            commit('CHANGE_DOMAIN_TYPE', newValue);
        },

        changeDomain({ commit }, newValue) {
            commit('CHANGE_DOMAIN', newValue);
        },

        changeSubDomain({ commit }, newValue) {
            commit('CHANGE_SUB_DOMAIN', newValue);
        },

        changeHostingPackage({ commit }, newValue) {
            commit('CHANGE_HOSTING_PACKAGE', newValue);
        },

        changeBackupCost({ commit }, newValue) {
            commit('CHANGE_BACKUP_COST', newValue);
        },

        changeIncludeStaging({ commit }, newValue) {
            commit('CHANGE_INCLUDE_STAGING', newValue);
        },

        changeIncludeBackup({ commit }, newValue) {
            commit('CHANGE_INCLUDE_BACKUP', newValue);
        },

        changeGeneralTerms({ commit }, newValue) {
            commit('CHANGE_GENERAL_TERMS', newValue);
        },

        changeBillingTerms({ commit }, newValue) {
            commit('CHANGE_BILLING_TERMS', newValue);
        },
        changeProductId({ commit }, newValue) {
            commit('CHANGE_PRODUCT_ID', newValue);
        },
        changeCustomerId({ commit }, newValue) {
            commit('CHANGE_CUSTOMER_ID', newValue);
        },
        changeId({ commit }, newValue) {
            commit('CHANGE_ID', newValue);
        },
        reset({ commit }){
            commit('RESET_STATE');
        },
        async fetchFormCreate({ commit }): Promise<any> {
            const response = await formCreate();
            const result = {
                hostingProviders: response.result.hosting_providers,
                hostingPackages: response.result.hosting_packages,
            };
            commit('CHANGE_FORM_CREATE', result);
        },
        async fetchFormEdit({ commit , state}): Promise<any> {
            const response = await getInstallation(state.form.id);
            commit('CHANGE_FORM', response.result);
        },
        async storeInstallation({ commit, state }): Promise<any> {
            return await storeInstallation(state.form);
        },
        async editInstallation({ commit, state }): Promise<any> {
            return await editInstallation(state.form);
        },
        async deleteInstallation({ commit }, id): Promise<any> {
            return await deleteInstallation(id);
        },
        async validateDomain({ commit, state }): Promise<any> {
            const response = await validateDomain(state.form.domain);
            commit('CHANGE_DOMAIN_TAKEN', response.isTaken);
        }
    }
}