import {formCreate, managePlugin, getGitPluginName, edit} from "@/services/PluginService";
import {getSoftwareVersions} from "../../services/SoftwareService";

export const plugin = {
    namespaced: true,
    state: {
        form: {
            id: null,
            name: null,
            measage: null,
            git_url: null,
            git_version_type_id: 1,
            accessibility_type: "public",
            availability: null,
            softwares: null,
            customers:null,
            price: "0.00",
            access_token: null,
        },
        formCreate: {
            types: [],
            availability: [],
            accessibility_types: [],
            softwares: [],
            base_git_url: null,
        },
        software_versions: []
    },
    mutations: {
        CHANGE_FORM_CREATE(state, data): void {
            state.formCreate = data;
        },
        CHANGE_SOFTWARE_VERSIONS(state, data): void {
            state.software_versions = data;
        },
        CHANGE_AVAILABILITY(state, value) {
            state.form.availability = value;
        },
        CHANGE_CUSTOMERS(state, value) {
            state.form.customers = value;
        },
        CHANGE_NAME(state, value) {
            state.form.name = value;
        },
        CHANGE_MESSAGE(state, value) {
            state.form.message = value;
        },
        CHANGE_ACCESSIBILITY_TYPE(state, value) {
            state.form.accessibility_type = value;
        },
        CHANGE_GIT_URL(state, value) {
            state.form.git_url = value;
        },
        CHANGE_ACCESS_TOKEN(state, value) {
            state.form.access_token = value;
        },
        CHANGE_GIT_VERSION_TYPE_ID(state, value) {
            state.form.git_version_type_id = value;
        },
        CHANGE_SOFTWARES(state, value) {
            state.form.softwares = value;
        },
        CHANGE_FORM(state, value) {
            Object.assign(state.form, value);
        },
        RESET_STATE(state, value) {
            Object.assign(state.form, {
                id: null,
                name: null,
                measage: null,
                git_url: null,
                git_version_type_id: 1,
                accessibility_type: "public",
                softwares: null,
                customers:null,
                price: "0.00",
                access_token: null,
            });
        }
    },
    actions: {
        async fetchFormCreate({ commit }): Promise<any> {
            const response = await formCreate();
            commit('CHANGE_FORM_CREATE', response.result);
        },
        updateGitUrl({ commit }, value) {
            commit('CHANGE_GIT_URL', value);
        },
        updateAccessToken({ commit }, value) {
            commit('CHANGE_ACCESS_TOKEN', value);
        },
        updateAccessibilityType({ commit }, value) {
            commit('CHANGE_ACCESSIBILITY_TYPE', value);
        },
        updateGitVersionTypeId({ commit }, value) {
            commit('CHANGE_GIT_VERSION_TYPE_ID', value);
        },
        updateAvailability({ commit }, value) {
            commit('CHANGE_AVAILABILITY', value);
        },
        updateCustomers({ commit }, value) {
            commit('CHANGE_CUSTOMERS', value);
        },
        updateSoftwares({ commit }, value) {
            commit('CHANGE_SOFTWARES', value);
        },
        async getSoftwareVersions({ commit, state }): Promise<any> {
            const response = await getSoftwareVersions(state.form.git_url, state.form.git_version_type_id);
            commit('CHANGE_SOFTWARE_VERSIONS', response.result);
        },
        async getGitPluginName({ commit, state }): Promise<any> {
            const response = await getGitPluginName(state.form.git_url, state.form.access_token);
            if(response){
                commit('CHANGE_NAME', response.name);
                commit('CHANGE_MESSAGE', response.message);
            }else{
                commit('CHANGE_NAME', '');
            }
        },
        async managePlugin({ commit, state }): Promise<any> {
            commit('CHANGE_GIT_URL', state.formCreate.base_git_url +  state.form.git_url.replace(state.formCreate.base_git_url, ""));
            return await managePlugin(state.form);
        },
        async edit({ commit, state }, params): Promise<any> {
            const response = await edit(params.id);
            commit('CHANGE_FORM', response);
            const versionsResponse = await getSoftwareVersions(state.form.git_url, state.form.git_version_type_id);
            commit('CHANGE_SOFTWARE_VERSIONS', versionsResponse.result);
        },
        reset({ commit }){
            commit('RESET_STATE');
        }
    }
}