import {Response} from "@/services/Response";
import {GET, POST} from "@/services/API";

export async function listSoftware(page:Number, perPage:Number, filter:String, sortBy: String, sortDesc: String): Promise<Response> {
    return await GET('software/list', {
        params: {
            filter: filter,
            page: page,
            per_page: perPage,
            sort_by: sortBy,
            sort_desc: sortDesc
        }
    });
}

export async function getSoftwareVersions(gitUrl:String, typeId:Number): Promise<Response> {
    return await GET('software/versions', {
        params: {
            git_url: gitUrl,
            git_version_type_id: typeId
        }
    });
}

export async function formCreate(): Promise<Response> {
    return await GET('software/form_create');
}

export async function manageSoftware(data): Promise<Response> {
    if(data.id) {
        // Update
        return await POST(`software/update/${ data.id }`, data);
    }
    return await POST('software/store', data);
}

export async function edit(id): Promise<Response> {
    return await GET(`software/edit/${id}`);
}