import {edit, manageCustomer} from "@/services/CustomerService";
import slugify from 'slugify';

export const customer = {
    namespaced: true,
    state: {
        formCreate: {
            invoiceTypes: [
                { value: 'email', text: 'Email' },
                { value: 'mail', text: 'Mail' },
            ]
        },
        form: {
            id: null,
            name : null,
            slugified_name: null,
            organization_no : null,
            invoice_type : null,
            invoice_address : null,
            invoice_email : null,
            invoice_reference : null,
            invoice_annotation : null
        },
    },
    mutations: {
        CHANGE_NAME(state, newValue) {
            state.form.name = newValue;
        },
        CHANGE_SLUGIFY_NAME(state, newValue) {
            state.form.slugified_name = newValue;
        },
        CHANGE_ORGANIZATION_NO(state, newValue) {
            state.form.organization_no = newValue;
        },
        CHANGE_INVOICE_TYPE(state, newValue) {
            state.form.invoice_type = newValue;
        },
        CHANGE_INVOICE_ADDRESS(state, newValue) {
            state.form.invoice_address = newValue;
        },
        CHANGE_INVOICE_EMAIL(state, newValue) {
            state.form.invoice_email = newValue;
        },
        CHANGE_INVOICE_REFERENCE(state, newValue) {
            state.form.invoice_reference = newValue;
        },
        CHANGE_INVOICE_ANNOTATION(state, newValue) {
            state.form.invoice_annotation = newValue;
        },
        CHANGE_FORM(state, value) {
            Object.assign(state.form, value);
        },
        RESET_STATE(state, value) {
            Object.assign(state.form, {
                id: null,
                name : null,
                slugified_name: null,
                organization_no : null,
                invoice_type : null,
                invoice_address : null,
                invoice_email : null,
                invoice_reference : null,
                invoice_annotation : null
            });
        }
    },
    actions: {
        changeName({ commit }, newValue) {
            commit('CHANGE_NAME', newValue);
        },
        changeSlugifyName({ commit }, newValue) {
            commit('CHANGE_SLUGIFY_NAME', newValue);
        },
        changeOrganizationNo({ commit }, newValue) {
            commit('CHANGE_ORGANIZATION_NO', newValue);
        },
        changeInvoiceType({ commit }, newValue) {
            commit('CHANGE_INVOICE_TYPE', newValue);
        },
        changeInvoiceAddress({ commit }, newValue) {
            commit('CHANGE_INVOICE_ADDRESS', newValue);
        },
        changeInvoiceEmail({ commit }, newValue) {
            commit('CHANGE_INVOICE_EMAIL', newValue);
        },
        changeInvoiceReference({ commit }, newValue) {
            commit('CHANGE_INVOICE_REFERENCE', newValue);
        },
        changeInvoiceAnnotation({ commit }, newValue) {
            commit('CHANGE_INVOICE_ANNOTATION', newValue);
        },
        manageCustomer({ commit, state }): Promise<any> {
            return manageCustomer(state.form);
        },
        async edit({ commit, state }, params): Promise<any> {
            const response = await edit(params.id);
            commit('CHANGE_FORM', response);
        },
        reset({ commit }){
            commit('RESET_STATE');
        }
    },
}