import {AxiosResponse} from "axios";
import {GET, POST} from "@/services/API";
import {Response} from "@/services/Response";

export async function manageCustomer(data): Promise<Response> {

    if(data.id) {
        return await POST(`customer/update/${data.id}`, data);
    }

    return await POST('customer/store', data);
}

export async function listCustomers(page:Number, perPage:Number, filter:String): Promise<Response> {
    return await GET('customer/list', {
        params: {
            filter: filter,
            page: page,
            per_page: perPage
        }
    });
}

export async function edit(id): Promise<Response> {
    return await GET(`customer/edit/${id}`);
}