import { getInstallationForManage, buildInstallation} from "../../services/InstallationService";

export const installationManage = {
    namespaced: true,
    state: {
        formCreate: {
            id: null,
            product: {id:null},
            software: {id:null},
            types: [],
        },
        form: {
            id: null,
            supported_version: null,
            supported_version_type : null,
            removedPlugins: [],
            addedPlugins: [],
            target: 'prod',
            restart_target: 'completed',
            scheduled_at: null
        }
    },
    mutations: {
        CHANGE_ID(state, newValue) {
            state.form.id = newValue;
        },
        CHANGE_FORM_CREATE(state, data): void {
            state.formCreate = data;
        },
        CHANGE_TARGET(state, data): void {
            state.form.target = data;
        },
        CHANGE_RESTART_TARGET(state, data): void {
            state.form.restart_target = data;
        },
        CHANGE_SCHEDULE_AT(state, data): void {
            state.form.scheduled_at = data;
        },
        
        CHANGE_SUPPORTED_VERSION_TYPE(state, data): void {
            state.formCreate.product.supported_version_type = data;
            state.form.supported_version_type = data;
        },
        CHANGE_SUPPORTED_VERSION(state, data): void {
            state.formCreate.product.supported_version = data;
            state.form.supported_version = data;
        },
        DELETE_PLUGIN(state, data): void {
            const indexToRemove = state.formCreate.product.plugins.findIndex(obj => obj.id === data);
            if (indexToRemove !== -1) {
                state.formCreate.product.plugins.splice(indexToRemove, 1);
                state.form.removedPlugins.push(data);
            }
        },
        RESET_STATE(state, value) {
            Object.assign(state.form, {
                plugins: [],
                removedPlugins: [],
                addedPlugins: [],
            });
        }
    },

    actions: {
        changeId({ commit }, newValue) {
            commit('CHANGE_ID', newValue);
        },
        changeSupportedVersionType({ commit }, newValue) {
            commit('CHANGE_SUPPORTED_VERSION_TYPE', newValue);
        },
        changeSupportedVersion({ commit }, newValue) {
            commit('CHANGE_SUPPORTED_VERSION', newValue);
        },
        changeTarget({ commit }, newValue) {
            commit('CHANGE_TARGET', newValue);
        },
        changeRestartTarget({ commit }, newValue) {
            commit('CHANGE_RESTART_TARGET', newValue);
        },
        changeScheduleAt({ commit }, newValue) {
            commit('CHANGE_SCHEDULE_AT', newValue);
        },
        deletePlugin({ commit }, newValue) {
            commit('DELETE_PLUGIN', newValue);
        },
        reset({ commit }){
            commit('RESET_STATE');
        },
        async fetchInstallation({ commit , state}): Promise<any> {
            const response = await getInstallationForManage(state.form.id);
            commit('CHANGE_FORM_CREATE', response.result[0]);
        },
        async buildInstallation({ commit , state}): Promise<any> {
            await buildInstallation(state.form);
        }
    }
}