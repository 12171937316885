import {Response} from "@/services/Response";
import {GET, POST, PUT} from "@/services/API";

export async function formCreate(): Promise<Response> {
    return await GET('plugin/form_create');
}

export async function getSoftwarePlugins(sortBy, sortDesc , filter, softwareId, versionType, version): Promise<Response> {
    return await GET('plugin/get_software_plugins', {
        params: {
            sort_by: sortBy,
            sort_desc: sortDesc,
            filter: filter,
            software_id: softwareId,
            version_type: versionType,
            supported_version: version
        }
    });
}

export async function managePlugin(data): Promise<Response> {
    // Update
    if(data.id) {
        return await POST(`plugin/update/${data.id}`, data);
    }

    return await POST('plugin/store', data);
}

export async function listPlugins(page:number, perPage:number, filter:string,sortBy: string, sortDesc: string): Promise<Response> {
    return await GET('plugin/list', {
        params: {
            filter: filter,
            page: page,
            per_page: perPage,
            sort_by: sortBy,
            sort_desc: sortDesc
        }
    });
}


export async function syncPlugin(id:number): Promise<Response> {
    return await PUT(`plugin/sync/${id}`, {});
}

export async function edit(id): Promise<Response> {
    return await GET(`plugin/edit/${id}`);
}

export async function getGitPluginName(gitUrl:string, accessToken:string): Promise<any> {
    return await GET('plugin/get_git_plugin_name', {
        params: {
            url: gitUrl,
            token: accessToken
        }
    });
}

export async function getPluginVersions(pluginId:string, moodleVersion:string, softwareId:string, softwareVersionType:string): Promise<any> {
    return await GET(`plugin/${pluginId}/versions`, {
        params: {
            supported_version: moodleVersion,
            software_id: softwareId,
            version_type: softwareVersionType
        }
    });
}