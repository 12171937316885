import store from "@/state/store";

export default [
    {
        path: "/customers",
        name: "customers",
        meta: { title: "Customers", authRequired: true, authCode : "CUSTOMERS_CUSTOMERS_READ" },
        component: () => import("../views/customers/list.vue"),
    },
    {
        path: "/customer/create/:id?",
        name: "customer_create",
        meta: { title: "Add New Customer", authRequired: true, authCode : "CUSTOMERS_CUSTOMERS_CREATE" },
        component: () => import("../views/customers/manage.vue"),
    },
    {
        path: "/customer/:id/products",
        name: "customer_products",
        meta: { title: "Customer Products", authRequired: true, authCode : "PRODUCTS_PRODUCTS_READ" },
        component: () => import("../views/customers/products/customer-products.vue"),
    },
    {
        path: "/customer/:id/product/create/:productId?",
        name: "customer_product_create",
        meta: { title: "Add New Product", authRequired: true, authCode : "PRODUCTS_PRODUCTS_CREATE" },
        component: () => import("../views/customers/products/manage-product.vue"),
    },
    {
        path: "/customer/product/instance/manage/:environment/:id",
        name: "customer_product_instance_manage",
        meta: { title: "Manage Instance", authRequired: true, authCode : "INSTALLATIONS_INSTALLATIONS_UPDATE" },
        component: () => import("../views/customers/products/manage-instance.vue"),
    },
    {
        path: "/customer/:customer_id/products/:product_id/installation/create",
        name: "customer_product_installation_create",
        meta: { title: "Add New Installation", authRequired: true, authCode : "INSTALLATIONS_INSTALLATIONS_CREATE" },
        component: () => import("../views/installations/create.vue"),
    },
    {
        path: "/customer/products/installation/:id/update",
        name: "customer_product_installation_update",
        meta: { title: "Add New Installation", authRequired: true, authCode : "INSTALLATIONS_INSTALLATIONS_UPDATE" },
        component: () => import("../views/installations/create.vue"),
    },
    {
        path: "/customer/products/installation/manage/:id?",
        name: "customer_product_installation_manage",
        meta: { title: "Manage Installation", authRequired: true, authCode : "INSTALLATIONS_INSTALLATIONS_UPDATE" },
        component: () => import("../views/installations/manage.vue"),
    },
    {
        path: "/product/settings/:id",
        name: "product_settings",
        meta: { title: "Product Settings", authRequired: true, authCode : "PRODUCTS_PRODUCTS_UPDATE" },
        component: () => import("@/views/product-manage/products/settings.vue"),
    },
    {
        path: "/product-manage",
        name: "products",
        meta: { title: "Product Package", authRequired: true, authCode : "PRODUCTS_PRODUCTS_READ" },
        component: () => import("@/views/product-manage/products-manage.vue"),
    },
    {
        path: "/product/create/:id?",
        name: "product_create",
        meta: { title: "Add New Product Package", authRequired: true, authCode : "PRODUCTS_PRODUCTS_CREATE" },
        component: () => import("../views/product-manage/products/manage.vue"),
    },
    {
        path: "/software/create/:id?",
        name: "software_create",
        meta: { title: "Add New Base Package", authRequired: true, authCode : "PRODUCTS_PRODUCTS_SOFTWARE_CREATE" },
        component: () => import("../views/product-manage/softwares/manage.vue"),
    },
    {
        path: "/plugin/create/:id?",
        name: "plugin_create",
        meta: { title: "Add New Plugin", authRequired: true, authCode : "PRODUCTS_PRODUCTS_PLUGINS_CREATE" },
        component: () => import("../views/product-manage/plugins/manage.vue"),
    },
    {
        path: "/hosting/create/:id?",
        name: "hosting_create",
        meta: { title: "Add New Hosting", authRequired: true, authCode : "PRODUCTS_PRODUCTS_HOSTING_CREATE" },
        component: () => import("../views/product-manage/hosting/manage.vue"),
    },
    {
        path: "/user/create",
        name: "user_create",
        meta: { title: "Add New User", authRequired: true, authCode : "USERS_USERS_CREATE" },
        component: () => import("../views/users/create.vue"),
    },
    {
        path: "/user/profile",
        name: "user_profile",
        meta: { title: "User Profile", authRequired: true, authCode : "USERS_USERS_CREATE" },
        component: () => import("../views/users/create.vue"),
    },
    {
        path: "/resellers",
        name: "resellers",
        meta: { title: "Resellers", authRequired: true, authCode : "RESELLERS_RESELLERS_READ" },
        component: () => import("../views/resellers/list.vue"),
    },
    {
        path: "/reseller/create",
        name: "reseller_create",
        meta: { title: "Add New Reseller", authRequired: true, authCode : "RESELLERS_RESELLERS_CREATE" },
        component: () => import("../views/resellers/manage.vue"),
    },
    {
        path: "/reseller/manage/:id",
        name: "reseller_manage",
        meta: { title: "Manage Reseller", authRequired: true, authCode : "RESELLERS_RESELLERS_UPDATE" },
        component: () => import("../views/resellers/manage.vue"),
    },
    {
        path: "/installations",
        name: "installations",
        meta: { title: "Installations", authRequired: true, authCode : "INSTALLATIONS_INSTALLATIONS_READ" },
        component: () => import("../views/installations/list.vue"),
    },
    {
        path: "/logs",
        name: "Logs",
        meta: { title: "Logs", authRequired: true, authCode : "LOGS_LOGS_READ" },
        component: () => import("../views/logs/list.vue"),
    },
    {
        path: "/documentation",
        name: "Documentation",
        meta: { title: "Documentation", authRequired: true, authCode : "DOCUMENTATION_DOCUMENTATION_READ" },
        component: () => import("../views/documentation/list.vue"),
    },
    {
        path: "/invoicing",
        name: "Invoicing",
        meta: { title: "Invoicing", authRequired: true, authCode : "INVOICING_INVOICING_READ" },
        component: () => import("../views/invoicing/list.vue"),
    },
    {
        path: "/accesscontrol/modules",
        name: "access_control_modules_list",
        meta: { title: "Access Control Modlues", authRequired: true, authCode : "ACCESS_CONTROL_ACCESS_CONTROL_MODULES_READ" },
        component: () => import("../views/access-control/modules/list.vue"),
    },
    {
        path: "/accesscontrol/modules/create",
        name: "access_control_modules_create",
        meta: { title: "Create Access Control Modlue", authRequired: true, authCode : "ACCESS_CONTROL_ACCESS_CONTROL_MODULES_CREATE" },
        component: () => import("../views/access-control/modules/create.vue"),
    },
    {
        path: "/accesscontrol/modules/update/:id",
        name: "access_control_modules_update",
        meta: { title: "Create Access Control Modlue", authRequired: true, authCode : "ACCESS_CONTROL_ACCESS_CONTROL_MODULES_UPDATE" },
        component: () => import("../views/access-control/modules/create.vue"),
    },
    {
        path: "/accesscontrol/permissions",
        name: "access_control_permissions_list",
        meta: { title: "Access Control Permissions", authRequired: true, authCode : "ACCESS_CONTROL_ACCESS_CONTROL_PERMISSIONS_READ" },
        component: () => import("../views/access-control/permissions/list.vue"),
    },
    {
        path: "/accesscontrol/permissions/create",
        name: "access_control_permissions_create",
        meta: { title: "Create Access Control Permissions", authRequired: true, authCode : "ACCESS_CONTROL_ACCESS_CONTROL_PERMISSIONS_CREATE" },
        component: () => import("../views/access-control/permissions/create.vue"),
    },
    {
        path: "/accesscontrol/permissions/update/:id",
        name: "access_control_permissions_update",
        meta: { title: "Create Access Control Permissions", authRequired: true, authCode : "ACCESS_CONTROL_ACCESS_CONTROL_PERMISSIONS_UPDATE" },
        component: () => import("../views/access-control/permissions/create.vue"),
    },
    {
        path: "/accesscontrol/role-permission",
        name: "access_control_role_permissions_list",
        meta: { title: "Access Control Role Permissions", authRequired: true, authCode : "ACCESS_CONTROL_ACCESS_CONTROL_ROLE_PERMISSIONS_READ" },
        component: () => import("../views/access-control/role-permissions/list.vue"),
    },

    {
        path: "/users",
        name: "user_list",
        meta: { title: "Users", authRequired: true, authCode : "USERS_USERS_READ" },
        component: () => import("../views/users/list.vue"),
    },
    {
        path: "/users/create",
        name: "user_create",
        meta: { title: "Users", authRequired: true, authCode : "USERS_USERS_CREATE" },
        component: () => import("../views/users/create.vue"),
    },
    {
        path: "/users/update/:id",
        name: "user_update",
        meta: { title: "Users", authRequired: true, authCode : "USERS_USERS_UPDATE" },
        component: () => import("../views/users/create.vue"),
    },
    {
        path: "/",
        name: "default",
        meta: { title: "Dashboard", authRequired: true, authCode : "DASHBOARD_DASHBOARD_READ" },
        // component: () => import("../views/dashboards/ecommerce/index.vue"),
        component: () => import("../views/dashboards/analytics/index.vue"),
        // component: () => import("../views/dashboards/crm/index.vue"),
        // component: () => import("../views/dashboards/learning/index.vue"),
        // component: () => import("../views/dashboards/realEstate/index.vue"),
    },


    ///////////////////////////////////////////////////////authRequired
    {
        path: "/login",
        name: "login",
        component: () => import("../views/account/login.vue"),
        meta: {
            title: "Login",
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                if (store.getters["auth/loggedIn"]) {
                    // Redirect to the home page instead
                    next({ name: "default" });
                } else {
                    // Continue to the login page
                    next();
                }
            },
        },
    },
    {
        path: "/logout",
        name: "logout",
        meta: { title: "Logout", authRequired: true, authCode : "DASHBOARD_DASHBOARD_READ", beforeResolve(routeTo, routeFrom, next) { 
            localStorage.clear(); 
            sessionStorage.clear(); 
            next();
        }, 
    },
        component: () => import("../views/account/login.vue"),
    },
    {
        path: "/error/404",
        name: "404",
        meta: { title: "Not found"},
        component: () => import("../views/error/404.vue"),
    },
    {
        path: "/error/401",
        name: "401",
        meta: { title: "Unauthorized"},
        component: () => import("../views/error/401.vue"),
    },
];