import {GET, POST, HandleError, HandleSuccess} from "@/services/API";
import {AxiosResponse} from "axios";
import {Response} from "@/services/Response";

export async function readUser(filter: {any}): Promise<Response> {
    return await GET('user/readuser', {
        params: filter
    }
    );
}

export async function createUser(data : {any}){
    return await POST('user/createuser', data).then(response => {
        HandleSuccess(response);
    })
    .catch(error => {
        HandleError(error);
    });
}
export async function profileUpdate(data : {any}){
    return await POST('user/profile', data).then(response => {
        HandleSuccess(response);
    })
    .catch(error => {
        HandleError(error);
    });
}

export async function updateUser(payload : {any}){
    return await POST('user/updateuser/' + payload['entityid'], payload['data']).then(response => {
        HandleSuccess(response);
    })
    .catch(error => {
        HandleError(error);
    });
}

export async function readAccountType(): Promise<Response> {
    return await GET('user/readaccounttypes', {});
}

export async function readCompanies(): Promise<Response> {
    return await GET('user/readcompanies', {});
}
